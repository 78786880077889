
import { HashRouter, Route, Routes } from "react-router"
import NotFound from "./pages/NotFound"
import Home from "./pages/Home"
import Layout from "./components/Layout"
import Downloads from "./pages/Downloads"




export const RoutePages = () => {
  return <HashRouter>
    <Routes>
          <Route path="/" Component={Layout} >
            <Route path="" Component={Home} />
            <Route path="downloads" Component={Downloads} />
          </Route>
          <Route path="*" Component={NotFound}/>
    </Routes>
  </HashRouter>
}