import { Box, Button, Flex, Hide, Menu, MenuButton, MenuItem, MenuItemProps, MenuList, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Show, Text, Image, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, useBoolean } from "@chakra-ui/react";
import { Outlet, useNavigate } from "react-router";
import { urlMap } from "../../constants/urls";
import { MenuIcon } from "../Icon";
const commonStyle: MenuItemProps = {
  bg: "black",
  color: "white",
  _hover: {
    background: "whiteAlpha.300",
  }
}


export default function Layout() {
  const [download, downloadOp] = useBoolean(false)
  const [join, joinOp] = useBoolean(false)
  const [open, openOp] = useBoolean(false)
  const nav = useNavigate()
  return (
    <Box>
<header>
        <Box className="logo" title="牛牛，使用脑力波" onClick={() => {nav("/")}}>
          <Image boxSize="32px" src={"https://cogagent.cn-bj.ufileos.com/static_agent_logo.png"} />
        <div className="productName">
          <Text pl=".5em">
            GLM-PC
          </Text>
        </div>
        </Box>
        {/* <div className="space"></div> */}
        <div className="space"></div>
        <Show breakpoint="(max-width: 800px)">
          <Menu>
            <MenuButton as={Button} size="sm" color="white" bg='black' _hover={{
              background: "black",
            }} _active={{ background: "black"}} variant="outline" px="2px" >
                <MenuIcon boxSize="20px" fill="white" />
            </MenuButton>
            <MenuList bg="black" minW="50px">
              <MenuItem as={"p"} onClick={downloadOp.on} {...commonStyle} >内测下载</MenuItem>
              <MenuItem as={"p"} onClick={joinOp.on} {...commonStyle} >加入社群</MenuItem>
              <MenuItem as={"a"} href={urlMap.技术文档} target={"_blank"} {...commonStyle} >技术文档</MenuItem>
              <MenuItem as={"a"} href={urlMap.用户文档} target={"_blank"} {...commonStyle} >用户文档</MenuItem>
              <MenuItem as={"a"} href={urlMap.教学视频} target={"_blank"} {...commonStyle} >教学视频</MenuItem>
              <MenuItem as={"p"} onClick={openOp.on} {...commonStyle} >意见反馈</MenuItem>
            </MenuList>
          </Menu>
        </Show>
        <Hide breakpoint="(max-width: 800px)">
          <div className="nav">
           <Box onClick={() => nav("/downloads")}>
            内测下载
           </Box>
            <Popover >
              <PopoverTrigger >
                <Box cursor="pointer">
                加入社群
                </Box>
              </PopoverTrigger>
              <PopoverContent bg="black" maxW="300px" _focusVisible={{ boxShadow: "none", outline: "none" }}>
                <PopoverArrow bg="black"  />
                <PopoverBody userSelect="text" _focusVisible={{ boxShadow: "none", outline: "none" }} fontSize="14px" >
                  <Image src="https://cogagent.aminer.cn/static/media/glm-pc-wx_1.jpeg" />
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <div><a href={urlMap.技术文档} target={"_blank"} rel="noreferrer">技术文档</a></div>
            <div><a href={urlMap.用户文档} target={"_blank"} rel="noreferrer">用户文档</a></div>
            <div><a href={urlMap.教学视频} target={"_blank"} rel="noreferrer">教学视频</a></div>
            <Popover >
              <PopoverTrigger >
                <Box cursor="pointer">
                意见反馈
                </Box>
              </PopoverTrigger>
              <PopoverContent bg="black" maxW="300px" _focusVisible={{ boxShadow: "none", outline: "none" }}>
                <PopoverArrow bg="black"  />
                <PopoverBody userSelect="text" _focusVisible={{ boxShadow: "none", outline: "none" }} fontSize="14px" >
                如果您有想对我们说的建议或者反馈，可以用文字、图片、视频进行反馈，发送到<a href="mailto:cogagent@zhipuai.cn" >cogagent@zhipuai.cn</a>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </div>
        </Hide>
        <Modal isOpen={open} onClose={openOp.off} isCentered>
        <ModalOverlay />
        <ModalContent bg="black" border="1px solid white" w="80vw" pb="20px">
          <ModalHeader>意见反馈</ModalHeader>
          <ModalBody color="white">
          如果您有想对我们说的建议或者反馈，可以用文字、图片、视频进行反馈，发送到<a href="mailto:cogagent@zhipuai.cn" >cogagent@zhipuai.cn</a>
          </ModalBody>
        </ModalContent>
      </Modal>
        <Modal isOpen={download} onClose={downloadOp.off} isCentered>
        <ModalOverlay />
        <ModalContent bg="black" border="1px solid white" w="80vw" pb="20px">
          <ModalHeader>内测下载</ModalHeader>
          <ModalBody color="white">
            手机端不支持下载，请您到电脑端官网下载
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={join} onClose={joinOp.off} isCentered>
        <ModalOverlay />
        <ModalContent bg="black" border="1px solid white" w="80vw" pb="20px">
          <ModalHeader>加入社群</ModalHeader>
          <ModalBody color="white">
            <Image src="https://cogagent.aminer.cn/static/media/glm-pc-wx_1.jpeg" />
          </ModalBody>
        </ModalContent>
      </Modal>
      </header>
      <Outlet />
    </Box>
  )
}
