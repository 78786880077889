export const urlMap = {
  "加入社群": "https://github.com/THUDM/CogAgent",
  "技术文档": "https://cogagent.aminer.cn/blog#/articles/cogagent-9b-20241220-technical-report",
  "用户文档": "https://cogagent.aminer.cn/static/agreement/%E7%94%A8%E6%88%B7%E6%89%8B%E5%86%8C.pdf",
  "意见反馈": "https://0ggfznkwh4j.typeform.com/to/WfuYTxMM?typeform-source=www.openinterpreter.com ",
  "教学视频": "https://zhipu-ai.feishu.cn/docx/PVEdd0C6yoZJl5xevsRcupYtnvg",
  "隐私政策": "https://cogagent.aminer.cn/static/agreement/GLM-PC%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.pdf",
  "用户协议": "https://cogagent.aminer.cn/static/agreement/GLM-PC%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.pdf",
  "关于我们": "https://www.zhipuai.cn/"
}
